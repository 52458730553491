import * as React from "react";
import {createRef, useEffect, useReducer, useRef, useState} from "react";
import {escapeHTML} from "../../utils";
import {IconButton, Stack} from "@mui/material";
import ContentEditable from "react-contenteditable";
import {Clear, Delete, Done} from "@mui/icons-material";

const EditingPanel = ({editing, text, setEditing, defaultValue, onSave, value, mandatory}) => {
    return <div style={{position: 'relative'}}>
        <Stack direction='row' justifyContent='center' sx={{
            ml: '10px',
            display: editing ? null : 'none',
            position: 'absolute',
            zIndex: 5,
            top: '-12px',
            borderRadius: '3px',
            padding: '0 3px',
            backgroundColor: 'rgba(0,0,0,0.3)'
        }}>
            <IconButton sx={{p: 0}} onClick={() => {
                if (text.current.length < 1) {
                    //todo: give some feedback
                    return
                }
                setEditing(false)
                if (text.current !== defaultValue) onSave(text.current);
            }}>
                <Done sx={{color: 'green', size: 'small'}}/>
            </IconButton>
            <IconButton sx={{p: 0}} onClick={() => {
                setEditing(false)
                text.current = value ?? defaultValue
            }}>
                <Clear sx={{color: 'red', size: 'small'}}/>
            </IconButton>
            <IconButton sx={{p: 0, display: mandatory ? 'none' : null}} onClick={() => {
                setEditing(false)
                onSave(undefined)
            }}>
                <Delete sx={{color: 'white', height: '20px'}}/>
            </IconButton>
        </Stack>
    </div>;
}

const ProfileText = ({editable, value, onSave, defaultValue, maxChars, extraStringValidation, mandatory, sx}) => {
    const [editing, setEditing] = useState(false)
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const text = useRef(value ?? defaultValue)
    const ref = createRef()
    useEffect(() => {
        if (editable && !editing) ref.current.blur()
    }, [editing])
    useEffect(() => {
        if (!editing) text.current = value ?? defaultValue
        forceUpdate()
    }, [value])
    const handleChange = evt => {
        let inputText = evt.currentTarget.innerText.trim();
        if (extraStringValidation) inputText = extraStringValidation(inputText)
        text.current = escapeHTML(inputText.substring(0, maxChars))
    };
    return (
        <Stack direction='row' alignItems='center' sx={{maxWidth: '100%'}}>
            <ContentEditable
                innerRef={ref}
                disabled={!editable}
                className='editable-profile-field'
                html={text.current}
                onFocus={() => setEditing(true)}
                onBlur={() => { if (editing) ref.current.focus() }}
                onChange={handleChange}
                style={{...sx, maxWidth: '100%', display: (value != null || editable) ? null : 'none', overflowWrap: 'anywhere'}}
            />
            <EditingPanel mandatory={mandatory} text={text} editing={editing} setEditing={setEditing} value={value} defaultValue={defaultValue} onSave={onSave}/>
        </Stack>
    )
}

export default ProfileText