import {Box, Paper, Stack, Typography} from "@mui/material";
import Image from "react-bootstrap/Image";
import XDILogo from "../assets/partners/xdi.png"
import XDLFGLogo from "../assets/partners/xdlfg.png"
import XDCLogo from "../assets/partners/xdc.png"
import {Link} from "react-router-dom";
import {TitleText} from "../components/Text";
import {useIsMobileHook} from "../utils";

const aboutText =
    <p>
        XDLoadout is a small project run by just one person. Initially I just threw it
        together as a personal project to
        practice some web dev skills (I'm a backend engineer professionally) but I really appreciate the support I've
        seen for the site. Since then, the project has grown and grown whilst waiting for the game to drop.
        <br/><br/>
        So far the XDefiant community has been a really positive place to be and I want this tool to
        be powered by the community so please <a style={{color: '#ddd'}}
                                                 href="https://twitter.com/XDLoadout">share</a> any
        suggestions/wishes/issues you have and I'd love to put them into action!
    </p>

const partners = [
    {
        name: "XDI.gg",
        description: "XDI is the leading competitive XDefiant resource and community. The XDI discord server is where you'll find the best and most passionate XDefiant players.",
        link: "https://xdi.gg",
        image: XDILogo
    },
    {
        name: "XDLFG",
        description: "XDLFG is a buzzing discord server and community where you can find players to party up with.",
        link: "https://discord.com/invite/XDLFG",
        image: XDLFGLogo
    },
    {
        name: "XDefiant Central",
        description: "XDefiant Central is a project run by two of the most dedicated and knowledgeable members of the XDefiant community. There you'll find the latest news and in-depth breakdowns of all things XDefiant.",
        link: "https://twitter.com/XDefiantCentral",
        image: XDCLogo
    }
]

const AboutPage = ({}) => {
    const isMobile = useIsMobileHook()
    return <Stack width='100%' alignItems='center'>
        <Stack width={isMobile ? '100%' : '80%'} alignItems='center'>
            <Box sx={{p: {xs: '5px', md: '15px'}}}>
                <TitleText>
                    About XDLoadout
                </TitleText>
                <Typography color='text.primary'>
                    {aboutText}
                </Typography>
                <Typography variant='h5' color='text.primary' sx={{pt: '35px'}}>
                    Friends & Partners
                </Typography>
            </Box>
            <Stack width={isMobile ? '100%' : '75%'} alignSelf='start' alignItems='stretch'>
                {partners.map((partner) =>
                    <Link to={partner.link} style={{textDecoration: 'none'}}>
                        <Paper sx={{p: '10px', m: '10px', '&:hover': {backgroundColor: '#282B2F'}}}>
                            <Stack direction='row' minHeight='80px'>
                                <Image
                                    style={{paddingRight: '10px', objectFit: 'contain'}}
                                    width='80px'
                                    src={partner.image}
                                    fluid
                                    rounded
                                />
                                <Stack>
                                    <Typography color='text.primary' fontSize='20px'>
                                        {partner.name}
                                    </Typography>
                                    <Typography color='text.primary'>
                                        {partner.description}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Link>
                )}
            </Stack>
        </Stack>
    </Stack>
}

export default AboutPage