import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import {ItemPaper} from "./WeaponBuilder";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useMemo} from "react";
import {WeaponImage} from "../components/WeaponImage";
import RemoveIcon from "@mui/icons-material/Close";
import CompareIcon from '@mui/icons-material/QueryStats';
import {allWeapons} from "./WeaponData";
import {randomInt} from "../utils";
import {Namespaces} from "../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

const isFull = (comparisonLoadouts) => comparisonLoadouts.length > 4

const Compare = ({savedLoadouts = [], comparisonLoadouts, setComparisonLoadouts}) => {
    const {t} = useTranslation(Namespaces.builder)
    const filteredSavedLoadouts = useMemo(() => savedLoadouts.filter(l => comparisonLoadouts.find(cl => cl.id === l.id) == null), [savedLoadouts, comparisonLoadouts])

    return <Accordion elevation={0} disableGutters sx={{m: '0 5px', maxHeight: '56px', flex: 1, borderRadius: '4px'}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
            aria-controls="compare-content"
            id="compare-header"
        >
            <Stack direction='row' alignItems='center' spacing='10px'>
                <CompareIcon sx={{color: 'white'}}/>
                <Typography variant='h6'>{t('compare')}</Typography>
            </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{p: 0}}>
            <ItemPaper elevation={0} sx={{
                p: '8px 16px 16px',
                m: 0,
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 8px 10px 0px rgba(0,0,0,0.12)'
            }}>
                <Stack sx={{width: '100%'}}>
                    <Typography fontSize='0.8rem' fontStyle='oblique'>{
                        isFull(comparisonLoadouts) ?
                            t('comparisonFull')
                            : t('comparisonSelect')
                    }</Typography>
                    <Stack direction='row' spacing='10px' mt='8px' minHeight='41px'>
                        {
                            comparisonLoadouts.map(l =>
                                <Stack direction='row' sx={{
                                    p: '3px',
                                    borderRadius: '5px',
                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                    alignItems: 'center'
                                }}>
                                    <Stack sx={{alignItems: 'center', mr: '7px', ml: '5px'}}>
                                        <Typography fontSize='0.8rem' fontStyle='oblique'
                                                    textAlign='center'>{l.name === `Stock ${l.weapon.name}` ? t('stock') : l.name}</Typography>
                                        <Typography fontSize='0.65rem' sx={{color: '#bbb'}}>{l.weapon.name}</Typography>
                                    </Stack>
                                    <IconButton sx={{p: 0}} onClick={() => {
                                        comparisonLoadouts.splice(comparisonLoadouts.findIndex(cl => cl.id === l.id), 1)
                                        setComparisonLoadouts([...comparisonLoadouts])
                                    }}>
                                        <RemoveIcon sx={{color: 'white', fontSize: '1.1rem'}}/>
                                    </IconButton>
                                </Stack>)
                        }
                    </Stack>
                    <Stack direction='row' spacing='20px' padding='10px 15px 0' display={isFull(comparisonLoadouts) ? 'none' : 'auto'}>
                        <Stack width='100%'>
                            <Typography>{t('savedLoadouts')}</Typography>
                            <List sx={{
                                width: '100%',
                                flexGrow: 1,
                                maxHeight: '30vh',
                                overflowY: 'scroll'
                            }}
                            >
                                {
                                    Object.values(filteredSavedLoadouts).map(l => {
                                            return <ListItemButton
                                                key={`compare-saved-loadout-${l.id}`}
                                                onClick={(e) => {
                                                    if (!isFull(comparisonLoadouts)) {
                                                        setComparisonLoadouts([...comparisonLoadouts, l])
                                                    }
                                                }}
                                                sx={{padding: 0}}
                                            >
                                                <ListItem sx={{padding: '0px'}}>
                                                    <Stack
                                                        sx={{
                                                            flexGrow: 0,
                                                            minWidth: '25%',
                                                            mr: '20px',
                                                            alignItems: 'center'
                                                        }}>
                                                        <WeaponImage sillhouette weapon={l.weapon.name} style={{
                                                            height: 'auto',
                                                            maxHeight: '25px',
                                                            maxWidth: '80px'
                                                        }}/>
                                                    </Stack>
                                                    <ListItemText primary={l.name} secondary={l.weapon.name}
                                                                  sx={{flexGrow: 0, textWrap: 'nowrap'}}/>
                                                </ListItem>
                                            </ListItemButton>;
                                        }
                                    )
                                }
                            </List>
                        </Stack>
                        <Stack width='100%'>
                            <Typography>{t('stockWeapons')}</Typography>
                            <List sx={{
                                width: '100%',
                                flexGrow: 1,
                                maxHeight: '30vh',
                                overflowY: 'scroll'
                            }}
                            >
                                {
                                    Object.values(allWeapons).map(w => {
                                            return <ListItemButton
                                                key={`compare-stock-weaponm-${w.name}`}
                                                onClick={(e) => {
                                                    if (!isFull(comparisonLoadouts)) {
                                                        setComparisonLoadouts([...comparisonLoadouts, {
                                                            weapon: w,
                                                            id: randomInt(9999999),
                                                            name: `Stock ${w.name}`,
                                                            attachments: {}
                                                        }])
                                                    }
                                                }}
                                                sx={{padding: 0}}
                                            >
                                                <ListItem sx={{padding: '0px'}}>
                                                    <Stack
                                                        sx={{
                                                            flexGrow: 0,
                                                            minWidth: '25%',
                                                            mr: '20px',
                                                            alignItems: 'center'
                                                        }}>
                                                        <WeaponImage sillhouette weapon={w.name} style={{
                                                            height: 'auto',
                                                            maxHeight: '25px',
                                                            maxWidth: '80px'
                                                        }}/>
                                                    </Stack>
                                                    <ListItemText primary={w.name} secondary={'Stock'}
                                                                  sx={{flexGrow: 0, textWrap: 'nowrap'}}/>
                                                </ListItem>
                                            </ListItemButton>;
                                        }
                                    )
                                }
                            </List>
                        </Stack>
                    </Stack>
                </Stack>
            </ItemPaper>
        </AccordionDetails>
    </Accordion>
}

export default Compare;
