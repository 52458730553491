import MainBg from "../assets/main-bg.png";
import {Dialog as DialogMui, DialogContent, IconButton, Slide, Stack} from "@mui/material";
import {forwardRef} from "react";
import {Close} from "@mui/icons-material";
import {useIsMobileHook} from "../utils";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const MobileCloseDialogButton = ({setOpen}) => {
    return <IconButton sx={{position: 'fixed', right: '5px', top: 0}} onClick={e => {
        setOpen(false);
        e.stopPropagation()
    }}><Close htmlColor='white'/></IconButton>;
}

const Dialog = ({open, setOpen, children}) => {
    const isMobile = useIsMobileHook()
    return <DialogMui onClose={(e) => {
        setOpen(false)
        e.stopPropagation()
    }} open={open} maxWidth='95vw' fullScreen={isMobile} TransitionComponent={Transition}>
        <Stack sx={{backgroundImage: 'linear-gradient(170deg, #3c3c40 0%, #1F1F21 100%)'}}>
            <DialogContent sx={{
                p: 0,
                backgroundImage: `url(${MainBg})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                minWidth: isMobile ? '90%' : '1200px',
                maxHeight: '100%',
                width: isMobile ? '100%' : '50vw',
                height: '100%',
                m: 0,
                overflowX: 'clip',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {display: 'none'}
            }}>
                <Stack alignItems='center' sx={{p: {xs: '10px', md: '30px'}, maxHeight: isMobile ? 'calc(100vh - 20px)' : 'calc(100vh - 124px)', overflowY: 'scroll', '&::-webkit-scrollbar': {display: 'none'}}}>
                    {isMobile ? <MobileCloseDialogButton setOpen={setOpen}/> : null}
                    {children}
                    {isMobile ? <Stack width='100vw' minHeight='90px'/> : null}
                </Stack>
            </DialogContent>
        </Stack>
    </DialogMui>
}

export default Dialog