import {Stack} from "@mui/material";
import {weaponImageName} from "../../utils";
import {useState} from "react";
import AttachmentSlot from "./AttachmentSlot";
import {Overlay} from "../../components/Overlay";

const weaponImageOverrides = {
    P90: {margin: '0 0 0 100px'},
    'Vector .45': {margin: '0 0 0 100px'},
    RPK: {width: '85%'},
    M60: {width: '78%'},
    M870: {width: '75%'},
    'Double-Barreled': {width: '85%'},
    'MK 20 SSR': {width: '80%'},
    SVD: {width: '80%'},
    'TAC-50': {width: '80%'},
    M44: {width: '80%'},
    '686 Magnum': {margin: '0 0 0 100px'},
    '93R': {margin: '0 70px 0 0'}
}

const GunsmithEditor = ({selectedLoadout, availableAttachments, setSelectedAttachments}) => {
    const [openSlot, setOpenSlot] = useState(null)
    const slotsFull = Object.values(selectedLoadout?.attachments ?? []).filter((s) => s != null).length > 4

    const removeAttachment = (weapon, slot, setOpenSlot) => {
        if (weapon.type === 'Sniper Rifle' && slot === 'Optic') {
            setOpenSlot(slot)
            return
        }
        selectedLoadout.attachments[slot] = null
        setSelectedAttachments({...selectedLoadout?.attachments})
    }

    const handleAttachmentSelection = (attachment, slot) => {
        if (selectedLoadout.attachments[slot] === attachment) {
            selectedLoadout.attachments[slot] = null
        } else {
            if (slotsFull && selectedLoadout.attachments[slot] == null) return
            selectedLoadout.attachments[slot] = attachment;
        }
        setSelectedAttachments({...selectedLoadout.attachments})
    }

    if (selectedLoadout?.weapon == null) return null

    return <Stack sx={{
        width: "100%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        position: "relative"
    }}>
        <div style={{
            height: "200px",
            width: "90%",
            filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.6)',
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
            backgroundImage: `url(${require("../../assets/weapons/" + weaponImageName(selectedLoadout.weapon) + ".png")})`,
            ...weaponImageOverrides[selectedLoadout.weapon.name],

        }}/>
        <Overlay active={openSlot != null} setActive={() => setOpenSlot(null)}/>
        {
            Object.keys(availableAttachments)
                .filter((slot) => availableAttachments[slot]?.length > 0)
                .map((slot) => <AttachmentSlot key={`attachment-slot-${slot}`}
                    selectedLoadout={selectedLoadout}
                    slot={slot}
                    slotsFull={slotsFull}
                    openSlot={openSlot} setOpenSlot={setOpenSlot}
                    availableAttachments={availableAttachments}
                    handleAttachmentSelection={handleAttachmentSelection}
                    removeAttachment={removeAttachment}/>)
        }
    </Stack>
}


export default GunsmithEditor;
