import {IconButton, Stack, TextField, Typography} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import XIcon from '@mui/icons-material/X';
import {DiscordIcon, KickIcon, TiktokIcon, TwitchIcon} from "../../components/Icons";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import {Clear, Delete, Done} from "@mui/icons-material";
import {UserContext} from "../../context/UserContext";
import {updateSocials} from "../../api/user/UpdateUser";
import {FeedbackContext, FeedbackType} from "../../context/UserFeedback";
import {string} from "yup";

const socialOptions = {
    twitch: {
        name: 'Twitch',
        icon: <TwitchIcon sx={{'&:hover': {filter: 'drop-shadow(0px 0px 2px #6441a5) '.repeat(3)}}}/>,
        url: (u) => `https://twitch.tv/${u}`,
        validation: string().notRequired().min(3).max(25).matches(/^[a-zA-Z0-9_]+$/)
    },
    youtube: {
        name: 'YouTube',
        icon: <YouTubeIcon sx={{color: 'white', '&:hover': {filter: 'drop-shadow(0px 0px 2px #c4302b) '.repeat(2)}}}/>,
        url: (u) => `https://youtube.com/@${u}`,
        username: 'handle',
        prefix: '@',
        validation: string().notRequired().min(3).max(30).matches(/^[a-zA-Z0-9_.-]+$/)
    },
    x: {
        name: 'X',
        icon: <XIcon sx={{
            color: 'white',
            fontSize: '1.4rem',
            '&:hover': {filter: 'drop-shadow(0px 0px 2px #1DA1F2aa) '.repeat(2)}
        }}/>,
        url: (u) => `https://x.com/${u}`,
        username: 'handle',
        prefix: '@',
        validation: string().notRequired().min(1).max(15).matches(/^[a-zA-Z0-9_]+$/),
    },
    tiktok: {
        name: 'TikTok',
        icon: <TiktokIcon
            sx={{'&:hover': {filter: 'drop-shadow(-1px -1px 2px #00f2eaaa) '.repeat(2) + 'drop-shadow(1px 1px 2px #ff0050aa) '.repeat(2)}}}/>,
        url: (u) => `https://tiktok.com/@${u}`,
        prefix: '@',
        validation: string().notRequired().min(1).max(24).matches(/^[a-zA-Z0-9_.]+$/),
    },
    discord: {
        name: 'Discord',
        icon: <DiscordIcon
            sx={{fontSize: '1.3rem', '&:hover': {filter: 'drop-shadow(0px 0px 2px #5865F2) '.repeat(2)}}}/>,
        url: (u) => `https://discord.gg/${u}`,
        username: 'server invite code',
        validation: string().notRequired().min(1).max(30).matches(/^[a-zA-Z0-9_.-]+$/),
    },
    kick: {
        name: 'Kick',
        icon: <KickIcon sx={{'&:hover': {filter: 'drop-shadow(0px 0px 2px #61CB2Bbb) '.repeat(2)}}}/>,
        url: (u) => `https://kick.com/${u}`,
        validation: string().notRequired().min(3).max(25).matches(/^[a-zA-Z0-9_]+$/),
    },
    instagram: {
        name: 'Instagram',
        icon: <InstagramIcon sx={{
            color: 'white',
            '&:hover': {filter: 'drop-shadow(-1px 1px 2px #5B51D888) drop-shadow(1px 1px 2px #C1358488) drop-shadow(1px -1px 2px #E1306C88) drop-shadow(-1px -1px 2px #FFDC8088)'}
        }}/>,
        url: (u) => `https://instagram.com/${u}`,
        username: 'handle',
        prefix: '@',
        validation: string().notRequired().min(3).max(30).matches(/^[a-zA-Z0-9_.]+$/)
    }
}

const EditingPanel = ({editing, setEditing, socials}) => {
    const {user, setUser} = useContext(UserContext)
    const {triggerFeedback} = useContext(FeedbackContext)
    const [editSocials, setEditSocials] = useState({})
    const [inputError, setInputError] = useState([])
    const [focusedInput, setfocusedInput] = useState(null)
    useEffect(() => {
        if (editing) {
            const blankSocials = {...socialOptions}
            Object.keys(blankSocials).forEach(s => blankSocials[s] = socials[s] == null ? null : socials[s])
            setEditSocials({...blankSocials});
        }
    }, [editing, socials])
    useEffect(() => {
        setInputError(Object.keys(socialOptions)
            .filter(s => !socialOptions[s].validation.isValidSync(editSocials[s])))
    }, [editSocials])
    return <Stack justifyContent='center' onClick={e => e.stopPropagation()} sx={{
        top: '115%',
        left: 0,
        display: editing ? null : 'none',
        position: 'absolute',
        zIndex: 6,
        borderRadius: '3px',
        padding: '10px',
        backgroundColor: 'rgba(15,15,15,0.9)'
    }}>
        {
            Object.keys(socialOptions).map(social =>
                <Stack key={`social-input-${social}`} direction='row' alignItems='center' sx={{pl: '3px'}}>
                    <Stack sx={{minWidth: '30px', alignItems: 'center'}}>{socialOptions[social].icon}</Stack>
                    <TextField size='small' variant='outlined'
                               value={`${socialOptions[social].prefix ?? ''}${editSocials[social] ?? (focusedInput === social ? '' : (socialOptions[social].username ?? 'username'))}`}
                               onFocus={() => setfocusedInput(social)}
                               onBlur={() => setfocusedInput(focusedInput === social ? null : focusedInput)}
                               onChange={e => {
                                   editSocials[social] = e.target.value.replaceAll('@', '').trim()
                                   editSocials[social] = editSocials[social] === '' ? null : editSocials[social]
                                   setEditSocials({...editSocials})
                               }}
                               sx={{
                                   margin: '3px',
                                   minWidth: {xs: '150px', md: '180px'},
                                   input: {
                                       color: editSocials[social] == null ? '#999' : 'white',
                                       fontSize: '0.9rem'
                                   },
                                   '& .MuiOutlinedInput-root': {
                                       '&.Mui-focused fieldset': {
                                           borderColor: 'white'
                                       }
                                   }
                               }} error={inputError.includes(social)}/>
                    <IconButton sx={{p: 0}} onClick={() => {
                        editSocials[social] = null
                        setEditSocials({...editSocials})
                    }}>
                        <Delete sx={{color: 'white', height: '20px'}}/>
                    </IconButton>
                </Stack>
            )

        }
        <Stack direction='row' sx={{mt: '5px'}} justifyContent='flex-end'>
            <IconButton sx={{p: 0}} onClick={() => {
                if (inputError.length > 0) {
                    triggerFeedback('One or more of your socials is invalid.', FeedbackType.ERROR)
                    return
                }
                updateSocials(editSocials)
                    .then(u => {
                        setUser({...u, icon: user.icon});
                        triggerFeedback('Socials successfully updated!', FeedbackType.SUCCESS)
                    })
                    .catch(() => triggerFeedback('Failed to update socials. Please report this to @XDLoadout on twitter. ):', FeedbackType.ERROR))
                setEditing(false)
            }}>
                <Done sx={{color: 'green', size: 'small'}}/>
            </IconButton>
            <IconButton sx={{p: 0}} onClick={() => {
                setEditing(false)
            }}>
                <Clear sx={{color: 'red', size: 'small'}}/>
            </IconButton>
        </Stack>
    </Stack>
}

const SocialButton = ({type, username}) => <IconButton sx={{p: '1px'}} onClick={() => {
    window.open(socialOptions[type].url(username), '_blank')
}}>
    {socialOptions[type].icon}
</IconButton>

const EditSocialsButton = ({isOwnProfile, socials}) => {
    const [editing, setEditing] = useState(false)
    return isOwnProfile ?
        <Stack direction='row' alignItems='center' sx={{p: '1px 0', ml: '5px'}} onClick={() => setEditing(!editing)}>
            <Typography sx={{
                p: '1px 0 0 3px',
                fontSize: '0.8rem',
                color: 'white',
                userSelect: 'none',
                '&:hover': {filter: 'drop-shadow(0px 0px 3px #c8c8c8aa)'}
            }}>
                Edit Socials
            </Typography>
            <EditingPanel editing={editing} setEditing={setEditing} socials={socials}/>
        </Stack> : null
}

const UserSocials = ({socials, isOwnProfile}) => {
    socials = socials ?? {}
    return <Stack direction='row' alignItems='center' justifyContent='flex-start' sx={{m: '5px 0 0 3px', position: 'relative', width: '100%'}}>
        {
            Object.keys(socialOptions)
                .filter(social => socials[social] != null)
                .map(social => <SocialButton key={`social-button-${social}`} type={social} username={socials[social]}/>)
        }
        <EditSocialsButton isOwnProfile={isOwnProfile} socials={socials}/>
    </Stack>
}

export default UserSocials