import {IconButton, Stack, Typography} from "@mui/material";
import {displayAttachmentName} from "../attachments/MobileAttachments";
import RemoveIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import AttachmentMenu from "./AttachmentMenu";
import {Namespaces} from "../../config/localisation/Localisation";
import {useTranslation} from "react-i18next";


const defaultSlotPositions = {
    Muzzle: {left: '1%', top: '42%'},
    Barrel: {left: '15%', top: '8%'},
    Front_Rail: {left: '15%', bottom: '35%'},
    Optic: {right: '25%', top: '8%'},
    Stock: {right: '1%', top: '50%'},
    Magazine: {left: '30%', bottom: '8%'},
    Rear_Grip: {right: '20%', bottom: '20%'}
}

const getSlotPosition = (slot, weapon) => {
    let overrides = {}
    switch (weapon?.name) {
        case 'MDR':
            overrides = {Magazine: defaultSlotPositions.Rear_Grip, Rear_Grip: defaultSlotPositions.Magazine}
            break
        case 'MP7':
            overrides = {Rear_Grip: {right: '25%', bottom: '22%'}, Magazine: {left: '35%', bottom: '10%'}}
            break
        case 'P90':
            overrides = {
                Muzzle: {left: '1%', top: '50%'},
                Barrel: {left: '8%', top: '35%'},
                Front_Rail: {left: '8%', bottom: '25%'},
                Optic: {left: '35%', top: '10%'},
                Magazine: {left: '55%', top: '30%'},
                Rear_Grip: {left: '40%', bottom: '8%'}
            }
            break
        case 'Vector .45':
            overrides = {
                Barrel: {left: '8%', top: '20%'},
                Front_Rail: {left: '10%', bottom: '35%'},
                Optic: {right: '30%', top: '8%'},
                Magazine: {left: '30%', bottom: '5%'},
                Rear_Grip: {right: '23%', bottom: '18%'}
            }
            break
        case 'M249':
            overrides = {
                Magazine: {left: '35%', bottom: '10%'},
                Rear_Grip: {right: '17%', bottom: '22%'}
            }
            break
        case 'M60':
            overrides = {
                Muzzle: {left: '1%', bottom: '38%'},
                Barrel: {left: '15%', top: '15%'},
                Front_Rail: {left: '18%', bottom: '22%'},
                Magazine: {left: '45%', bottom: '8%'},
                Rear_Grip: {right: '10%', bottom: '22%'}
            }
            break
        case 'M870':
            overrides = {
                Barrel: {left: '15%', top: '12%'},
                Optic: {right: '25%', top: '12%'},
                Magazine: {left: '35%', bottom: '10%'},
                Rear_Grip: {right: '20%', bottom: '23%'}
            }
            break
        case 'AA-12':
            overrides = {
                Magazine: {left: '37%', bottom: '8%'},
            }
            break
        case 'Double-Barreled':
            overrides = {
                Barrel: {left: '15%', top: '15%'},
                Stock: {right: '1%', top: '25%'},
                Rear_Grip: {right: '25%', bottom: '30%'}
            }
            break
        case 'MK 20 SSR':
            overrides = {
                Muzzle: {left: '1%', top: '50%'},
                Barrel: {left: '12%', top: '15%'},
                Front_Rail: {left: '18%', bottom: '29%'},
                Optic: {right: '25%', top: '10%'},
                Magazine: {left: '35%', bottom: '7%'},
                Rear_Grip: {right: '18%', bottom: '18%'}
            }
            break
        case 'SVD':
            overrides = {
                Muzzle: {left: '1%', top: '50%'},
                Barrel: {left: '15%', top: '18%'},
                Front_Rail: {left: '25%', bottom: '28%'},
                Optic: {right: '28%', top: '20%'},
                Magazine: {left: '35%', bottom: '7%'},
                Rear_Grip: {right: '12%', bottom: '18%'}
            }
            break
        case 'M1911':
        case 'M9':
        case 'D50':
            overrides = {
                Muzzle: {left: '1%', top: '42%'},
                Optic: {right: '40%', top: '8%'},
                Magazine: {left: '45%', bottom: '8%'},
                Rear_Grip: {right: '1%', bottom: '35%'}
            }
            break
        case '686 Magnum':
            overrides = {
                Muzzle: {left: '5%', top: '25%'},
                Optic: {right: '35%', top: '8%'},
                Rear_Grip: {right: '10%', bottom: '8%'}
            }
            break
        case '93R':
            overrides = {
                Muzzle: {left: '1%', top: '25%'},
                Optic: {right: '38%', top: '8%'},
                Stock: {right: '1%', top: '40%'},
                Magazine: {left: '45%', bottom: '8%'},
                Rear_Grip: {right: '1%', bottom: '30%'}
            }
            break
        default:
            break
    }
    return {...defaultSlotPositions, ...overrides}[slot]
}

const AttachmentSlot = ({
                            selectedLoadout,
                            slot,
                            slotsFull,
                            openSlot,
                            setOpenSlot,
                            availableAttachments,
                            handleAttachmentSelection,
                            removeAttachment
                        }) => {
    const {t} = useTranslation(Namespaces.attachments)
    const isSelected = selectedLoadout.attachments[slot] != null
    return slotsFull && !isSelected ? null :
        <Stack direction='row'
               onClick={() => {
                   if (!isSelected) setOpenSlot(slot)
               }}
               sx={{
                   alignItems: 'center',
                   p: '5px 8px',
                   backgroundColor: '#00000088',
                   transition: 'all 0.2s ease',
                   '&:hover': {backgroundColor: '#22222288', boxShadow: '0px 0px 8px 0px white'},
                   borderRadius: '5px',
                   position: 'absolute', ...getSlotPosition(slot, selectedLoadout.weapon)
               }}>
            <div style={{position: 'relative'}}>
                {
                    openSlot !== slot ? null :
                        <AttachmentMenu slot={slot}
                                        setOpenSlot={setOpenSlot}
                                        selectedLoadout={selectedLoadout}
                                        availableAttachments={availableAttachments}
                                        handleAttachmentSelection={handleAttachmentSelection}
                                        flipEffects={getSlotPosition(slot, selectedLoadout.weapon).right}/>
                }
            </div>
            <Typography sx={{userSelect: 'none'}}>
                {`${t(slot)}${isSelected ? ' - ' + t(displayAttachmentName(selectedLoadout.attachments[slot])) : ''}`}
            </Typography>
            {
                isSelected ?
                    <IconButton
                        onClick={() => removeAttachment(selectedLoadout.weapon, slot, setOpenSlot)}
                        sx={{p: 0}}>
                        <RemoveIcon sx={{color: 'white', p: '1px 0 0 2px'}}/>
                    </IconButton>
                    :
                    slotsFull ? null :
                        <IconButton onClick={() => setOpenSlot(slot)}
                                    sx={{p: 0}}>
                            <AddIcon sx={{color: 'white', p: '1px 0 0 3px'}}/>
                        </IconButton>

            }
        </Stack>;
}

export default AttachmentSlot;
