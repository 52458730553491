import * as React from "react";
import {useContext, useState} from "react";
import {FeedbackContext, FeedbackType} from "../../../context/UserFeedback";
import {Stack, Typography} from "@mui/material";
import Dialog from "../../../components/Dialog";
import {Refresh} from "@mui/icons-material";
import {retryStatsLink} from "../../../api/user/UpdateUser";
import {useIsMobileHook} from "../../../utils";
import DiscordLinkImage from "../../../assets/ubi-link/linkaccounts.PNG"
import {Namespaces} from "../../../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

const USERNAME_TAKEN = 'USERNAME_TAKEN';

const RefreshButton = ({sx}) => {
    const {triggerFeedback} = useContext(FeedbackContext)
    const [waiting, setWaiting] = useState(false)

    const tryLink = () => {
        setWaiting(true)
        retryStatsLink().then(() => setTimeout(() => window.location.reload(), 1500))
            .catch(e => {
                console.log(e)
                if (e.message === 'TRY_AGAIN_LATER') triggerFeedback('Link not found, try again in a few minutes.', FeedbackType.ERROR)
                if (e.message === 'ALREADY_LINKED') window.location.reload()
            }).finally(setWaiting(false))
    }

    return <Stack direction='row' alignItems='center' p='5px' mr='10px' borderRadius='10px' border='1px solid #999'
                  backgroundColor='rgba(80,80,80,0.2)' color='white' sx={{...sx, cursor: 'pointer'}}
                  onClick={tryLink}>
        <Typography variant='small'>Refresh</Typography><Refresh color='white'/>
    </Stack>;
}

export function LinkStatsDialog({dialogOpen, setDialogOpen}) {
    const {t} = useTranslation(Namespaces.playerStats)
    const isMobile = useIsMobileHook()
    return <Dialog open={dialogOpen} setOpen={setDialogOpen} minWidth={false}>
        <Stack height={isMobile ? 'calc(100vh - 110px)' : 'auto'} justifyContent='center'>
            <Typography variant='midHead' mb='10px' textAlign='center'>{t('howToLinkTitle')}</Typography>
            <Typography>1) {t('step1')} <a style={{color: '#ccc'}} target='_blank' rel='noopener noreferrer'
                                        href='https://account.ubisoft.com/account-information'>Ubisoft
                Connect</a></Typography>
            <Typography>2) {t('step2A')} <i>{t('step2B')}</i></Typography>
            <Typography>3) {t('step3A')} <i>{t('step3B')}</i></Typography>
            <img width={isMobile ? '95%' : '65%'} src={DiscordLinkImage}
                 style={{alignSelf: isMobile ? 'center' : 'start', borderRadius: '5px', margin: '5px 10px'}}/>
            <Typography>4) {t('step4')}</Typography>
            <Typography>5) {t('step5')}</Typography>
            <RefreshButton sx={{alignSelf: isMobile ? 'center' : 'start', m: '5px 10px'}}/>
        </Stack>
    </Dialog>;
}

const AddStats = () => {
    const isMobile = useIsMobileHook()
    const [dialogOpen, setDialogOpen] = useState(false)

    return <Stack direction='row' alignItems='center' justifyContent='center'
                  sx={{
                      mt: '15px',
                      height: isMobile ? '50px' : '35px',
                      width: '100%',
                      p: '10px 0',
                      borderRadius: '15px',
                      border: '1px dashed #aaa',
                      backgroundColor: 'rgba(0,0,0,0.2)',
                      // backgroundColor: 'rgba(50,50,50,0.2)'
                  }}>
        <Stack direction='row' alignItems='center'>
            <Typography variant='smallHead' color='white' m='0 20px 0 8px' textAlign='center'>
                To add ingame stats to your profile, you must <a
                style={{color: '#ccc', textDecoration: 'underline', cursor: 'pointer'}}
                onClick={() => setDialogOpen(true)}>
                link your Discord account with your Ubisoft account
            </a>.
            </Typography>
            <RefreshButton/>
        </Stack>
        <LinkStatsDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}/>
    </Stack>
}

export default AddStats