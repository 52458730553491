import {Stack} from "@mui/material";
import WeaponBuilder from "./WeaponBuilder";
import {useParams} from "react-router-dom";
import {useState} from "react";
import {useIsMobileHook} from "../utils";
import MobileAd, {MobileSlots} from "../components/ads/MobileAd";
import {Namespaces} from "../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

function WeaponScreen() {
    const {t} = useTranslation(Namespaces.builder)
    const [debugMode, setDebugMode] = useState(0)
    const isMobile = useIsMobileHook()

    return <Stack alignItems='center' onClick={() => setDebugMode(debugMode + 1)}>
        <h1 style={{
            textAlign: 'center',
            color: 'white',
            fontSize: isMobile ? '0.9rem' : '1rem',
            lineHeight: '1.25',
            fontWeight: 'normal',
            margin: 0
        }}>
            {t('h1Text')}
        </h1>
        <h2 style={{
            textAlign: 'center',
            color: 'white',
            fontSize: '0.875rem',
            lineHeight: '1.25',
            fontWeight: 'normal',
            margin: isMobile ? '5px 0' : '2px 0 7px',
            fontStyle: 'oblique'
        }}>
            {t('h2Text')}
        </h2>
        <MobileAd slot={MobileSlots.HORIZONTAL_1} nitroSlot='builder-1'/>
        <WeaponBuilder loadoutId={useParams().loadoutId} debugMode={debugMode}/>
    </Stack>
}

export default WeaponScreen;
