import {Dialog, Grow, List, ListItem, ListItemButton, ListItemText, Stack, Typography} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../context/UserContext";
import {Add} from '@mui/icons-material';
import {updateLoadouts} from "../../api/user/UpdateUser";
import UserLoadout from "../../components/loadout/UserLoadout";
import FavouriteLoadouts from "./loadouts/FavouriteLoadouts";
import LoadoutsByClass from "./loadouts/LoadoutsByClass";
import {FeedbackContext, FeedbackType} from "../../context/UserFeedback";
import LoadoutMenu from "./loadouts/LoadoutMenu";
import {UserProfileContext} from "./ProfilePage";
import MobileAd, {MobileSlots} from "../../components/ads/MobileAd";
import DesktopHorizontalBannerAd from "../../components/ads/DesktopHorizontalBannerAd";


function updateAddableLoadouts(setAddableWeapons, user) {
    setAddableWeapons((user.loadouts ?? []).filter((l) => !l.public))
}

const NoAddableLoadoutsScreen = () => {
    return (
        <Typography sx={{textAlign: 'center', fontSize: '0.9rem'}}>
            You have no more saved loadouts to add to your profile.<br/>
            Create and save loadouts <a href='/' style={{color: 'white'}}>here</a> to add them to your profile.
        </Typography>
    )
}

const AddLoadout = ({user, setUser}) => {
    const {triggerFeedback} = useContext(FeedbackContext)
    const [modalOpen, setModalOpen] = useState(false)
    const [addableLoadouts, setAddableLoadouts] = useState([])
    useEffect(() => updateAddableLoadouts(setAddableLoadouts, user), [user])
    return (
        <Stack direction='row' onClick={() => {
            if (!modalOpen) setModalOpen(true)
        }}
               sx={{
                   p: '10px',
                   borderRadius: '10px',
                   backgroundColor: 'rgba(0,0,0,0.2)',
                   alignItems: 'center',
                   justifyContent: 'center',
                   '&:hover': {backgroundColor: 'rgba(50,50,50,0.2)', boxShadow: '0px 0px 10px rgb(140 140 140)'}
               }}>
            <Typography sx={{color: 'rgba(255,255,255,0.8)', fontSize: '1.3rem', mr: '8px'}}>Add Loadout</Typography>
            <Add sx={{fontSize: '2.5rem', color: 'rgba(255,255,255,0.8)'}}/>
            <Dialog open={modalOpen} onClose={(e) => setModalOpen(false)} sx={{transition: 'height 2s'}}>
                <Grow in={modalOpen}>
                    <Stack sx={{p: '10px 50px'}}>
                        {addableLoadouts.length < 1 ?
                            <NoAddableLoadoutsScreen/>
                            :
                            [<Typography key='add-loadout-text'>Select a saved loadout to add to profile:</Typography>,
                                <List key='addable-loadouts' dense sx={{width: '100%', flexGrow: 1, overflow: 'auto'}}>
                                    {
                                        Object.values(addableLoadouts).map(loadout =>
                                            <ListItemButton
                                                key={`add-loadout-button-${loadout.id}`}
                                                onClick={() => {
                                                updateLoadouts([{...loadout, public: true}])
                                                    .then(u => {setUser({...u, icon: user.icon}); triggerFeedback('Loadout successfully added to profile!', FeedbackType.SUCCESS)})
                                                    .catch(() => triggerFeedback('Failed to add loadout to profile. Please report this to @XDLoadout on twitter. ):', FeedbackType.ERROR))
                                                setModalOpen(false)
                                            }}>
                                                <ListItem sx={{p: 0}}>
                                                    <ListItemText primary={loadout.name} secondary={loadout.weapon.name}/>
                                                </ListItem>
                                            </ListItemButton>
                                        )
                                    }
                                </List>
                            ]
                        }
                    </Stack>
                </Grow>
            </Dialog>
        </Stack>
    )
}

export const ProfileLoadout = ({loadout, isOwnProfile, hideButtons, showImage = true, showLogo = false}) => {
    const isUserProfile = Boolean(useContext(UserProfileContext))
    return <div style={{position: "relative"}}>
        <UserLoadout loadout={loadout} user={loadout.user} showLogo={showLogo} showUserChip={!isUserProfile} showImage={showImage}/>
        <LoadoutMenu loadout={loadout} hideButtons={hideButtons} isOwnProfile={isOwnProfile} />
    </div>
}

const ProfileLoadouts = ({data, isOwnProfile}) => {
    const {user, setUser} = useContext(UserContext)

    return (
        <Stack sx={{mt: {xs: 0, md: '20px'}, width: {xs: '100%', md: '80%'}, alignItems: 'center'}}>
            <FavouriteLoadouts loadouts={data.loadouts.filter((l) => l.favourite)} isOwnProfile={isOwnProfile}/>
            {data.loadouts.filter((l) => l.favourite).length > 0 ? <MobileAd slot={MobileSlots.HORIZONTAL_3} nitroSlot='profile-2'/> : null}
            {data.loadouts.filter((l) => l.favourite).length > 0 ? <DesktopHorizontalBannerAd nitroSlot='profile-loadouts'/> : null}
            <LoadoutsByClass loadouts={data.loadouts} isOwnProfile={isOwnProfile}/>
            {isOwnProfile ? <AddLoadout user={user} setUser={setUser}/> : null}
        </Stack>
    )
}
export default ProfileLoadouts