import {Stack} from "@mui/material";
import {TitleText} from "../components/Text";
import * as React from "react";
import MobileAd, {MobileSlots} from "../components/ads/MobileAd";

const BasePage = ({title, children}) => {
    return <Stack width='100%' alignItems='center'>
        <MobileAd slot={MobileSlots.HORIZONTAL_1} nitroSlot='base-1'/>
        <Stack sx={{mt: {xs: 0, md: '10px'}, width: {xs: '97%', md: '80%'}}}>
            <TitleText>
                {title}
            </TitleText>
            <Stack alignItems='center'>
                {children}
            </Stack>
        </Stack>
    </Stack>
}

export default BasePage