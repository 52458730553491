import {useSearchParams} from "react-router-dom";
import {Stack, Typography} from "@mui/material";
import {useMemo} from "react";
import {Base64} from "js-base64";
import Teams from "./Teams";

const decodeMatchData = (searchParams) => {
    try {
        return JSON.parse(decodeURIComponent(Base64.decode(searchParams.get('data'))))
    } catch (e) {
        console.error(e)
        throw new Error("Failed to parse matchData")
    }
}

const MatchData = () => {
    const [searchParams] = useSearchParams()
    const matchData = useMemo(() => decodeMatchData(searchParams), [searchParams])

    return <Stack alignItems='center' width='100%' m='50px 0'>
        <Typography variant='largeHead' alignSelf='start' ml='10px' sx={{textShadow: '0px 0px 5px black'}}>
            Match Summary - {matchData.gamemode}
        </Typography>
        <Teams teams={matchData.teams}/>
    </Stack>
}

export default MatchData