import {Stack, styled, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {buildWeapon, ItemPaper} from "./WeaponBuilder";
import AdvancedSettings from "./AdvancedSettings";
import {useIsMobileHook} from "../utils";
import {SettingsContext} from "../context/SettingsContext";
import Compare from "./Compare";
import NewGraph from "./NewGraph";
import {Namespaces} from "../config/localisation/Localisation";
import {useTranslation} from "react-i18next";


export const LightToggleButton = styled(ToggleButton)({
    '&.MuiToggleButton-root': {
        backgroundColor: 'rgba(0,0,0,0.15)',
        color: '#aaa',
    },
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: 'rgba(60,60,60,0.25)',
        color: '#fff',
    },
})

function TTKGraph({currentWeapon, savedLoadouts}) {
    const {t} = useTranslation(Namespaces.weaponStats)
    const {settings} = useContext(SettingsContext)

    const isMobile = useIsMobileHook()
    const [graphType, setGraphType] = useState('ttk')
    const [comparisonLoadouts, setComparisonLoadoutsState] = useState([])

    const setComparisonLoadouts = (loadouts) =>
        setComparisonLoadoutsState(loadouts.map((l) => {
            return {...l, calculatedWeapon: buildWeapon(l.weapon, l.attachments, settings).customWeapon, name: l.name}
        }))

    useEffect(() => {
        setComparisonLoadouts(comparisonLoadouts)
    }, [settings])

    const handleGraphType = (event, newGraphType) => {
        if (newGraphType != null) setGraphType(newGraphType)
    };

    return (
        <Stack>
            <Stack position='relative' minHeight='63px'>
                <Stack direction='row' position='absolute' zIndex='100'>
                    <AdvancedSettings/>
                    {isMobile ? null : <Compare savedLoadouts={savedLoadouts} comparisonLoadouts={comparisonLoadouts}
                                                setComparisonLoadouts={setComparisonLoadouts}/>}
                </Stack>
            </Stack>
            <ItemPaper sx={{maxHeight: '60vh'}}>
                <Stack sx={{height: '100%'}} alignItems='center' marginTop='10px'>
                    <ToggleButtonGroup
                        value={graphType}
                        exclusive
                        onChange={handleGraphType}
                        aria-label="graphType-select"
                    >
                        <LightToggleButton value="ttk" aria-label="left aligned">
                            {t('ttk')}
                        </LightToggleButton>
                        <LightToggleButton value="stk" aria-label="centered">
                            {t('stk')}
                        </LightToggleButton>
                        <LightToggleButton value="damage" aria-label="right aligned">
                            {t('damageRanges')}
                        </LightToggleButton>
                    </ToggleButtonGroup>
                    <NewGraph graphType={graphType} weapon={currentWeapon} comparisonLoadouts={comparisonLoadouts}/>
                </Stack>
            </ItemPaper>
        </Stack>
    )
}

export default TTKGraph
