import {IconButton, Stack, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import AddStats from "./AddStats";
import {numberFormatter, useIsMobileHook} from "../../../utils";
import {FactionIcon} from "../../../components/Images";
import AddIcon from "@mui/icons-material/Add";
import {Remove} from "@mui/icons-material";
import {unlinkStats} from "../../../api/user/UpdateUser";

const unlink = (e) => unlinkStats().then(() => {
    e.stopPropagation()
    window.location.reload()
})

const UserStats = ({expanded, isOwnProfile, stats}) => {
    return stats?.player ? <StatsPanel alwaysExpanded={expanded} isOwnProfile={isOwnProfile} stats={stats}/>
        : isOwnProfile && stats === 'NO_LINKED_ACCOUNT' ? <AddStats/>
            : null
}

const mainStats = ['kdr', 'winRatio', 'wins', 'mvpCount', 'mvpRate', 'killsPerGame', 'kills']
const mobileMainStats = ['kdr', 'winRatio', 'mvpRate', 'kills', 'killsPerGame']

const PlayerStat = ({stat}) => {
    const isMobile = useIsMobileHook()
    const extraWidth = stat.label.length > 12 || (isMobile ? false : stat.value?.toString()?.length > 6)

    function width() {
        if (isMobile) {
            // if (stat.label.length > 15) return '
            return extraWidth ? '95px' : '80px'
        } else {
            return extraWidth ? '100px' : '85px'
        }
    }

    return <Stack m='5px' alignItems='start' key={`stat-${stat.labelId}`}
                  width={width()} p={isMobile ? '3px 5px' : '4px 8px'} backgroundColor='rgba(0,0,0,0.2)' borderRadius='5px'
                  border='1px solid #444'>
        <Typography variant='small' color='#ccc'>{stat.label}</Typography>
        <Typography variant={isMobile ? 'midSmallHead' : 'midHead'}>{`${stat.value > 1000 ? numberFormatter.format(stat.value) : stat.value ?? 0}${stat.unit ?? ''}`}</Typography>
    </Stack>
}

export const LevelIcon = ({level, small}) => {
    return level == null ? null : <Typography variant='mid' sx={{
        fontSize: small ? level.toString().length > 2 ? '0.7rem' : '0.8rem'
            : level.toString().length > 2 ? '1rem' : '1.25rem',
        borderRadius: 15,
        border: '2px solid white',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: small ? '22px' : '30px',
        width: small ? '22px' : '30px'
    }}>{level}</Typography>;
}

export const RankIcon = ({rank, small}) => {
    if (rank == null) return null
    let rankImgName = rank.toLowerCase().replaceAll('_', '').replaceAll(' ', '')
    rankImgName = rankImgName.charAt(0).toUpperCase() + rankImgName.slice(1)
    try {
        return <img src={require(`../../../assets/game/rank/${rankImgName}.png`)} alt={rankImgName}
                    style={{height: small ? '25px' : '45px', width: small ? '25px' : '45px', paddingTop: '2px'}}/>
    } catch {
        return null
    }
}

export function PlayerProgression({stats, small = false}) {
    const isMobile = useIsMobileHook()
    return stats == null ? null : <Stack direction='row' alignItems='center' justifyContent='center'
                  m={isMobile && !small ? '0 10px' : '0 5px 0 0'} width={small ? '60px' : stats.level && stats.rank ? '100px' : '60px'}>
        <LevelIcon level={stats.level} small={small}/>
        <Stack minWidth='10px'/>
        <RankIcon rank={stats.rank} small={small}/>
    </Stack>
}

const MainStats = ({stats}) => {
    const isMobile = useIsMobileHook()
    return <Stack direction='row' alignItems='center' justifyContent='stretch' >
        <Stack direction='row' alignItems='center' justifyContent='center' flexGrow={1} flexWrap={isMobile ? 'wrap' : 'nowrap'} overflow='hidden'>
            <PlayerProgression stats={stats.progression}/>
            {stats.player.filter(s => (isMobile ? mobileMainStats : mainStats).includes(s.labelId))
                .map(stat => <PlayerStat key={`main-stats-${stat.labelId}`} stat={stat}/>)}
        </Stack>
    </Stack>
}

const StatsTable = ({title, stats, width, columnWidths}) => {
    const isMobile = useIsMobileHook()
    return stats ? <Stack width={isMobile ? 'calc(100% - 20px)' : width} m='5px' p={isMobile ? '5px' : '10px 15px'} border='1px solid #aaa' borderRadius={3}>
        <Typography variant='midHead' m='0 0 10px 0px'>{title}</Typography>
        <Stack direction='row' p='3px' backgroundColor='rgba(100,100,100,0.3)' borderRadius='3px'>
            {stats[0].map((stat, i) =>
                <Typography key={`title-${stat.labelId}`} variant={isMobile ? 'small' : 'smallHead'} fontStyle='oblique'
                            width={columnWidths[i]}>
                    {stat.label}
                </Typography>)}
        </Stack>
        {stats.map((row, i) =>
            <Stack direction='row' p='4px 0 4px 4px' key={row[0].label}
                   backgroundColor={`rgba(70,70,70,${0.1 * (i % 2)})`}
                   borderRadius={i === stats.length - 1 ? '0 0 3px 3px' : '0'}>
                {row.map((stat, i) =>
                    <Typography key={`${row}-${stat.labelId}`} variant='small' width={columnWidths[i]}>
                        {`${stat.value > 1000 ? numberFormatter.format(stat.value) : stat.value ?? 0}${stat.unit ?? ''}`}
                    </Typography>)}
            </Stack>
        )}
    </Stack> : null
}

const AllStats = ({stats, isOwnProfile}) => {
    const isMobile = useIsMobileHook()
    const categories = new Set(stats.player.map(st => st.category))


    return <Stack>
        <Stack direction='row'>
            <Typography variant='midHead' m='0 25px 5px 5px'>Career</Typography>
        </Stack>
        <Stack direction='row' alignItems='stretch' justifyContent='start' flexGrow={1} flexWrap='wrap'>
            <Stack m='5px' p='3px' direction='column' alignItems='start'
                   border='1px solid #aaa' borderRadius={3}>
                <Typography variant='smallHead' color='white' fontStyle='oblique' ml='10px'
                            height='100%'>Progression</Typography>
                <Stack direction='row' alignItems='center' p={isMobile ? '6px' : '12px 15px'}>
                    <PlayerProgression stats={stats.progression}/>
                </Stack>
            </Stack>
            {[...categories].map(cat =>
                <Stack m='5px' key={`stat-cat-${cat}`} p='3px' direction='column' alignItems='start'
                       border='1px solid #aaa' borderRadius={3}>
                    <Typography variant='smallHead' color='white' fontStyle='oblique' ml='10px'>{cat}</Typography>
                    <Stack direction='row' alignItems='center' flexWrap='wrap'>
                        {stats.player.filter(st => st.category === cat).map(stat => <PlayerStat
                            key={`${cat}-stat-${stat.labelId}`} stat={stat}/>)}
                    </Stack>
                </Stack>)}
            <Stack m='5px' p='3px' direction='column' alignItems='start'
                   border='1px solid #aaa' borderRadius={3}>
                <Typography variant='smallHead' color='white' fontStyle='oblique' ml='10px'
                            height='100%'>Favourite Faction</Typography>
                <Stack direction='row' alignItems='center' justifyContent='center' p={isMobile ? '6px 8px' : '10px 10px'}>
                    <FactionIcon faction={stats.factions.favourite} style={{height: '40px', width: '40px'}}/>
                    <Typography variant='midHead' ml='5px'>{stats.factions.favourite}</Typography>
                </Stack>
            </Stack>
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} justifyContent='space-between' width='100%'>
            <StatsTable title='Weapon Classes' stats={stats.weaponClasses} width='33%' columnWidths={['60%', '40%']}/>
            <StatsTable title='Gamemodes' stats={stats.gamemodes} width='66%'
                        columnWidths={['30%', '15%', '15%', '20%', '20%']}/>
        </Stack>
        <Stack direction='row' alignSelf='stretch' alignItems='center' justifyContent='space-between' mt='3px' p='0 5px'>
            {isOwnProfile ? <Typography variant='smallHead' color='white' p='3px 5px' borderRadius={3} backgroundColor='rgba(0,0,0,0.2)' sx={{cursor: 'pointer'}} onClick={unlink}>
                Unlink Stats
            </Typography> : <Stack/>}
            <Typography variant='small' fontStyle='oblique' color='#aaa'>
                {`Last updated: ${new Date(stats.lastUpdated).toLocaleString()}`}
            </Typography>
        </Stack>
    </Stack>

}

const statsMessage = <><i>Note that stats can differ from those shown ingame.</i> XDLoadout uses Ubisoft APIs which sometimes do
        not track correctly. <a style={{color: 'white'}} href="/updates" target='_blank' rel='noopener noreferrer'><i>More Here.</i></a></>

const StatsPanel = ({isOwnProfile, stats, alwaysExpanded}) => {
    const [expanded, setExpanded] = useState(false)
    const isMobile = useIsMobileHook()
    useEffect(() => { setExpanded(alwaysExpanded)}, [alwaysExpanded, expanded])

    if (!(stats.player && stats.progression)) return null


    const statsNoteHeight = isMobile ? '50px' : '20px';
    return <Stack direction='column' alignItems='stretch' justifyContent='center'
                  onClick={() => setExpanded(!expanded)}
                  sx={{
                      position: 'relative',
                      mt: '15px',
                      height: expanded ? 'auto' : isMobile ? '145px' : '60px',
                      width: `calc(100% - ${isMobile ? '12px' : '50px'})`,
                      p: isMobile ? '10px 5px' : '10px 25px',
                      borderRadius: '15px',
                      border: '1px solid #aaa',
                      color: 'white',
                      backgroundColor: 'rgba(0,0,0,0.2)',
                  }}>
        <Stack position='absolute' top='-1px' left='-1px' width='100%' height={statsNoteHeight}
               backgroundColor='#008cff3d' alignItems='center' justifyContent='center' borderRadius='15px 15px 0 0'
               border='1px solid #aaa'>
            <Typography variant='small' textAlign='center' sx={{textShadow: '0 0 4px black'}}
                        m='2px 5px'>{statsMessage}</Typography>
        </Stack>
        <Stack minHeight={statsNoteHeight}/>
        <Stack display={alwaysExpanded ? 'none' : null}position='absolute' top={statsNoteHeight} right={isMobile ? '2px' : '10px'} justifyContent='center'
               height={isMobile ? '50px' : '62px'}>
            <IconButton>
                {expanded ? <Remove sx={{color: 'white'}}/> : <AddIcon sx={{color: 'white'}}/>}
            </IconButton>
        </Stack>
        {!expanded ? <MainStats stats={stats}/>
            : <AllStats isOwnProfile={isOwnProfile} stats={stats}/>}
    </Stack>
}


export default UserStats