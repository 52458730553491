import {Skeleton, Stack} from "@mui/material";
import * as React from "react";
import {useContext, useState} from "react";
import {useIsMobileHook} from "../utils";
import {useNavigate, useParams} from "react-router-dom";
import {Namespaces} from "../config/localisation/Localisation";
import {useTranslation} from "react-i18next";
import UserStats from "./profile/stats/UserStats";
import BasePage from "./BasePage";
import {UserContext} from "../context/UserContext";

const StatTrackPage = ({}) => {
    const isMobile = useIsMobileHook()
    const category = useParams().category
    const navigate = useNavigate()
    const {user} = useContext(UserContext)
    const {t} = useTranslation(Namespaces.playerStats)
    const [dialogOpen, setDialogOpen] = useState(false)
    return <BasePage title={t('statTrackTitle')}>
        {user?.profile?.stats ? <UserStats expanded isOwnProfile={true} stats={user.profile.stats}/> : null}
    </BasePage>
}

const LeaderboardSkeleton = () => <Stack width='100%'>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
</Stack>

export default StatTrackPage