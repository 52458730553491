import {Typography} from "@mui/material";
import * as React from "react";

export const TitleText = (props) => <Typography {...props} sx={{
    mb: {xs: '5px', md: '15px'},
    color: 'white',
    textShadow: '0px 0px 5px black',
    fontSize: {xs: '1.5rem', md: '2rem'},
    fontWeight: 'bold',
    fontStyle: 'oblique'
}}/>