import {Stack, Typography} from "@mui/material";
import {decodeLoadoutId} from "../../builder/WeaponBuilder";
import AttachmentsSummary from "../../builder/share/AttachmentsSummary";
import {Link} from "react-router-dom";
import {useIsMobileHook} from "../../utils";
import CollabTag from "./CollabTag";
import LoadoutHighlights from "./LoadoutHighlights";
import UserChip from "./UserChip";
import {WeaponImage} from "../WeaponImage";
import XDLoadoutLogo from "../XDLoadoutLogo";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../config/localisation/Localisation";

const GoToLoadoutButton = ({loadoutId}) => {
    const {t} = useTranslation(Namespaces.builder)
    return (
        <Link target="_blank" to={`/loadouts/${loadoutId}`}
              style={{textDecoration: 'none'}}>
            <Stack direction='row'
                   sx={{
                       m: {xs: '2px 5px', md: '0 5px'},
                       height: '60%',
                       p: {xs: '1px 8px', md: '3px 5px'},
                       color: 'text.primary',
                       borderRadius: '5px',
                       backgroundColor: 'rgba(0,0,0,0.4)',
                       alignItems: 'center',
                       justifyContent: 'center',
                       transition: 'background-color 0.3s ease',
                       '&:hover': {backgroundColor: 'rgba(255,255,255,0.1)'}
                   }}>
                <Typography textAlign='center' fontSize={{xs: '0.9rem', md: '1rem'}}>{t('viewInBuilder')}</Typography>
            </Stack>
        </Link>
    )
}

const LoadoutTitle = ({loadout, showLogo, isMobile}) => {
    return <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'center' : null}>
        <XDLoadoutLogo height={isMobile ? '25px' : '43px'} style={{ display: showLogo ? null : 'none',
            padding: isMobile ? '5px 15px 0 15px' : '5px 60px 0 5px', filter: 'drop-shadow(0px 0px 8px rgba(0,0,0,0.8)'}}/>
        <Typography sx={{
            p: isMobile ? '5px 0' :'8px 0',
            fontSize: isMobile ? '1.75rem' : '2.5rem',
            fontWeight: 'bold',
            fontStyle: 'oblique',
            color: 'white',
            textAlign: 'center',
            lineHeight: 1,
            textShadow: '0px 0px 8px rgba(0,0,0,0.8)',
        }}>
            {loadout.name}
        </Typography>
    </Stack>
}

const cardStyles = {
    height: '95%',
    width: '95%',
    mt: '20px'
}

const UserLoadout = ({loadoutId, loadout = {loadoutId}, user, showUserChip, showImage, showLogo, card}) => {
    const isMobile = useIsMobileHook() && !card

    if (card) {
        showImage = true
        showLogo = true
        showUserChip = true
    }

    if (loadout.loadoutId == null) return
    const {loadoutAttachments, loadoutWeapon} = decodeLoadoutId(loadout.loadoutId)
    loadout.weapon = loadoutWeapon
    loadout.attachments = loadoutAttachments
    loadout.name = loadout.name ?? loadoutWeapon.name

    if (loadout?.loadoutId == null) return null

    return <Stack alignItems='center'
                  sx={card ? cardStyles : {
                      p: '10px',
                      mb: '15px',
                      borderRadius: '10px',
                      backgroundColor: 'rgba(0,0,0,0.2)'
                  }}>
        {isMobile && !card ? <Stack minHeight='40px' width='100%' justifyContent='end'>
            <div style={{
                height: '1px',
                width: '100%',
                marginBottom: '4px',
                background: 'radial-gradient(circle, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.1) 70%, rgba(255,255,255,0) 95%)'
            }}/>
        </Stack> : null}
        <LoadoutTitle loadout={loadout} showLogo={showLogo} isMobile={isMobile}/>
        {showImage ? <WeaponImage weapon={loadout?.weapon?.name} style={{
            height: 'auto',
            maxHeight: isMobile ? '150px' : '200px',
            maxWidth: '100%',
            objectFit: 'contain'
        }}/> : null}
        {loadout.description ? <Typography variant='smallHead' p='8px' m={isMobile ? '5px' : '5px 20%'} backgroundColor='rgba(0,0,0,0.2)' borderRadius='10px' textAlign='center'>{loadout.description}</Typography> : null}
        <Stack direction={isMobile ? 'column' : 'row'}
               justifyContent={showUserChip && user && !isMobile  ? 'space-between' : 'end'}
               alignItems='center'
               width='100%'
        >
            {isMobile || !showUserChip || !user?.id ? null : <UserChip user={user}/>}
            <Stack direction='row'
                   alignItems='center'
                   justifyContent={isMobile && !showUserChip ? 'center' : 'space-between'}
                   m='8px 0'
                   width={isMobile ? '90%' : 'auto'}
            >
                {isMobile ?
                    (showUserChip && user?.id ? <UserChip user={user} isMobile/> : null) :
                    <LoadoutHighlights attachments={loadout.attachments}/>
                }
                {card ? null : <GoToLoadoutButton loadoutId={loadout.id ?? loadout.loadoutId}/>}
            </Stack>
            {isMobile ? <LoadoutHighlights attachments={loadout.attachments} isMobile/> : null}
        </Stack>
        <AttachmentsSummary attachments={loadout.attachments} isMobile={isMobile}/>
        <CollabTag user={user}/>
    </Stack>
}

export default UserLoadout