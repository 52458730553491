import {Stack, styled, Typography} from "@mui/material";
import InfoTooltip from "../../components/InfoTooltip";
import {Namespaces} from "../../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

export const IncDecButton = styled('div')({
    height: '20px',
    width: '20px',
    backgroundColor: 'rgb(0,0,0,0.3)',
    textAlign: 'center',
    userSelect: 'none',
    borderRadius: '3px'
})


function BodyPartMultiplier({settings, handleMultiplierValue, part}) {
    const {t} = useTranslation(Namespaces.builder)
    return <Stack direction='row' justifyContent='start' alignItems='center'>
        <Typography variant='small' sx={{marginBottom: '3px', minWidth: '80px'}}>
            {t(`${part.toLowerCase()}shots`)}:
        </Typography>
        <Stack direction='row' alignItems='center' sx={{ml: '5px'}}>
            <IncDecButton
                onClick={() => handleMultiplierValue(settings.multipliers[part.toLowerCase()] - 1, part.toLowerCase())}>-</IncDecButton>
            <Typography variant='small' sx={{p: '0 5px', userSelect: 'none'}}>{settings.multipliers[part.toLowerCase()]}</Typography>
            <IncDecButton
                onClick={() => handleMultiplierValue(settings.multipliers[part.toLowerCase()] + 1, part.toLowerCase())}>+</IncDecButton>
        </Stack>
    </Stack>
}

const MultipliersSettings = ({settings, handleMultiplierValue}) => {
    const {t} = useTranslation(Namespaces.builder)
    return <Stack>
        <Stack direction='row' sx={{mb: '5px'}}>
            <Typography variant='smallHead'>{t('multipliers')}</Typography>
            <InfoTooltip text={t('multipliersTooltip')}/>
        </Stack>
        <Stack>
            <BodyPartMultiplier part={'Head'} handleMultiplierValue={handleMultiplierValue} settings={settings}/>
            <BodyPartMultiplier part={'Chest'} handleMultiplierValue={handleMultiplierValue} settings={settings}/>
            {/*<BodyPartMultiplier part={'Leg'} handleMultiplierValue={handleMultiplierValue} settings={settings}/>*/}
        </Stack>
    </Stack>
}

export default MultipliersSettings