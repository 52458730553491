import {Stack, Typography} from "@mui/material";
import BuilderButton from "../assets/builder-button.png"
import MetaButton from "../assets/meta-button.png"
import UserProfileButton from "../assets/userprofile-button.PNG"
import {useIsMobileHook} from "../utils";
import XDLoadoutLogo from "../components/XDLoadoutLogo";
import {Link} from "react-router-dom";
import {LoginScreen, userProfilePath} from "../LoginButton";
import React, {useContext} from "react";
import {UserContext} from "../context/UserContext";
import MobileAd, {MobileSlots} from "../components/ads/MobileAd";
import {Namespaces} from "../config/localisation/Localisation";
import {Trans, useTranslation} from "react-i18next";


function HomepageButton({image, title, description, path}) {
    const isMobile = useIsMobileHook()
    const {t} = useTranslation(Namespaces.homepage)

    return <Link to={path} style={{textDecoration: 'none'}}>
        <Stack p='15px 15px' backgroundColor='rgba(0,0,0,0.35)' borderRadius='5px'
               maxWidth='380px' height='calc(100% - 30px)' m='10px'
               alignItems='center' sx={{'&:hover': {boxShadow: '0px 0px 6px rgb(200 200 200)'}}}>
            <Stack width='100%' position='relative'>
                <img draggable={false} src={image} height={260} style={{
                    position: 'absolute', top: 0, left: 0,
                    height: 'auto', maxWidth: '100%', objectFit: 'contain',
                    WebkitMaskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
                    maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 76%)',
                }}/>
            </Stack>
            <Stack width='100%' mt='calc(min(50%, 200px))'>
                <Typography color='white' fontSize='1.5rem' fontWeight='bold' fontStyle='oblique' width='100%'
                            textAlign='left'>
                    {title}
                </Typography>
                <Typography color='white' fontSize='0.85rem' fontStyle='oblique'>
                    {description}
                </Typography>
            </Stack>
        </Stack>
    </Link>
}

const HomeScreen = () => {
    const {t} = useTranslation(Namespaces.homepage)
    const isMobile = useIsMobileHook()
    const {user, userLoading} = useContext(UserContext)

    const homepageButtons = [
        {
            title: t('builderTitle'),
            description: t('builderDesc'),
            image: BuilderButton,
            path: '/builder'
        },
        {
            title: t('metaTitle'),
            description: t('metaDesc'),
            image: MetaButton,
            path: '/meta'
        },
    ]

    return <Stack width='100%' alignItems='center'>
        <Stack m={isMobile ? '5px 0 15px 0' : '10px 0 20px 0'} width='100%' display={isMobile ? 'none' : null}
               direction={isMobile ? 'column' : 'row'} alignItems='center' justifyContent='center'>
            <Typography sx={{
                color: 'white',
                fontSize: '2.75rem',
                lineHeight: 1.5,
                fontStyle: 'oblique',
                fontWeight: 'bold',
                mr: '15px'
            }}>
                {t('welcome')}
            </Typography>
            <XDLoadoutLogo link={false} height={isMobile ? '45px' : '55px'}/>
        </Stack>
        <Stack width='100%' alignItems='center' justifyContent='center'>
            <Stack width={isMobile ? '100%' : '80%'}>
                <h1 style={{
                    textAlign: 'center',
                    color: 'white',
                    fontSize: isMobile ? '1rem' : '1.5rem',
                    fontStyle: 'oblique',
                    fontWeight: 'bold',
                    margin: isMobile ? '10px 15px' : '0 0 25px 0'
                }}>
                    <Trans t={t}>
                        {t('h1Text')}
                    </Trans>
                </h1>
            </Stack>
            <MobileAd slot={MobileSlots.HORIZONTAL_1} nitroSlot='home-1'/>
            <Stack direction={isMobile ? 'column' : 'row'} width={isMobile ? '100%' : '80%'} alignItems='stretch'
                   justifyContent='space-around'>
                {homepageButtons.map(button => <HomepageButton {...button}/>)}
            </Stack>
            {user || userLoading ?
                <Link to={userProfilePath(user)} style={{
                    textDecoration: 'none',
                    width: isMobile ? '100%' : '40%',
                    minWidth: isMobile ? null : '400px',
                    marginTop: isMobile ? '10px' : '30px'
                }}>
                    <Stack p='15px 15px' backgroundColor='rgba(0,0,0,0.35)' borderRadius='5px'
                           width='calc(100% - 30px)' height='100%'
                           alignItems='center' sx={{'&:hover': {boxShadow: '0px 0px 6px rgb(200 200 200)'}}}>
                        <Stack width='100%' position='relative'>
                            <img draggable={false} src={UserProfileButton} style={{
                                position: 'absolute', top: 0, left: 0,
                                WebkitMaskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
                                maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 76%)',
                                height: 'auto',
                                maxWidth: '100%',
                                objectFit: 'contain'
                            }}/>
                            <Typography mt='calc(min(185px, 35%))' color='white' fontSize='1.5rem' fontWeight='bold'
                                        fontStyle='oblique'
                                        width='100%' textAlign={isMobile ? 'left' : 'center'}>
                                {t('profileTitle')}
                            </Typography>
                            <Typography color='white' fontSize='0.85rem' fontStyle='oblique' width='100%'
                                        textAlign={isMobile ? 'left' : 'center'}>
                                {t('profileDesc')}
                            </Typography>
                        </Stack>
                    </Stack>
                </Link>
                :
                <Stack width={isMobile ? '95%' : '80%'} alignItems='center' justifyContent='center'
                       m={isMobile ? '5px' : '20px'}>
                    <h2 style={{
                        textAlign: 'center',
                        color: 'white',
                        fontSize: '1.2rem',
                        lineHeight: '1.25',
                        fontWeight: 'normal',
                        margin: '15px 0',
                        fontStyle: 'oblique',
                        display: isMobile ? 'none' : null
                    }}>
                        {t('h2Text')}
                    </h2>
                    <Stack p='10px 20px' borderRadius='5px' backgroundColor='rgba(0,0,0,0.35)' color='white'>
                        <LoginScreen/>
                    </Stack>
                </Stack>
            }
        </Stack>
    </Stack>
}

export default HomeScreen;
