import {useContext, useEffect, useRef, useState} from "react";
import Chart from 'chart.js/auto';
import {SettingsContext} from "../context/SettingsContext";
import {useIsMobileHook} from "../utils";
import zoomPlugin from 'chartjs-plugin-zoom';
import _ from "underscore";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../config/localisation/Localisation";

const lineColors = ['blue', 'green', 'yellow', 'cyan', 'pink']
const X_AXIS_MAX = 100;

function NewGraph({graphType, weapon, comparisonLoadouts}) {
    const isMobile = useIsMobileHook()
    const {settings} = useContext(SettingsContext)
    const graphRef = useRef()

    const calculateStk = (dmgRange) => {
        if (!dmgRange) return 'remove'
        let stk = 0
        let enemyHealth = settings.enemyHealth - settings.cleanerDamage

        if (settings.multipliers.head > 0) {
            let headshotsToKill = enemyHealth / dmgRange.damages.head
            if (headshotsToKill <= settings.multipliers.head) {
                return Math.ceil(headshotsToKill)
            }
            stk = settings.multipliers.head
            enemyHealth -= stk * dmgRange.damages.head
        }

        if (settings.multipliers.chest > 0) {
            let chestshotsToKill = enemyHealth / dmgRange.damages.chest
            if (chestshotsToKill <= settings.multipliers.chest) {
                return Math.ceil(stk + chestshotsToKill)
            }
            stk += settings.multipliers.chest
            enemyHealth -= settings.multipliers.chest * dmgRange.damages.chest
        }

            let bodyShotsToKill = enemyHealth / dmgRange.damages.body
            stk += bodyShotsToKill

            stk = Math.ceil(stk) / (settings.accuracy / 100)
            return Math.round(stk)
    }

    function calculateTtk(weapon, dmgRange) {
        let baseTtk = weapon.burstRateOfFire ? burstTTK(dmgRange, weapon)
            : (60000 / (weapon.rateOfFire)) * (calculateStk(dmgRange) - 1)
        let adsTime = settings.adsTtk ? (weapon.adsTime) : 0
        let stfTime = settings.stfTtk ? (weapon.sprintToFire) : 0
        return baseTtk + Math.max(adsTime, stfTime)
    }

    function burstTTK(dmgRange, weapon) {
        const rounds = 3;
        const shotsToKill = calculateStk(dmgRange);

        if (rounds >= shotsToKill) return (rounds - 1) * weapon.burstRateOfFire.burst

        const fullBursts = Math.floor(shotsToKill / rounds) * ((weapon.burstRateOfFire.burst * (rounds - 1)) + weapon.burstRateOfFire.pause)
        const remaining = ((shotsToKill % rounds) - 1) * weapon.burstRateOfFire.burst
        return fullBursts + remaining;
    }


    const getYValue = (range, weapon) => {
        const dmgRange = weapon.damageRanges.find(dr => range <= dr.range)
        switch (graphType) {
            case 'ttk':
                return calculateTtk(weapon, dmgRange)
            case 'stk':
                return calculateStk(dmgRange, settings)
            case 'damage':
                return dmgRange?.damage ?? 'remove'
        }
    }

    const calculateDatapoints = (weapon, colour, name) => {
        return {
            label: name ?? weapon.name,
            data: [...Array(X_AXIS_MAX).keys()].map(x => getYValue(x, weapon)).filter(x => x !== 'remove'),
            borderColor: colour,
            fill: false,
            stepped: true,
        }
    }

    const [data, setData] = useState({
        labels: [...Array(X_AXIS_MAX + 1).keys()].map(x => `${x}m`),
        datasets: []
    })

    const {t, i18n} = useTranslation(Namespaces.weaponStats)
    const [graph, setGraph] = useState()

    useEffect(() => {
            Chart.register(zoomPlugin)
            const chart = new Chart(graphRef.current, {
                type: 'line',
                data: data,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    elements: {
                        point: {
                            pointStyle: 'line',
                            radius: 0
                        }
                    },
                    interaction: {
                        intersect: false,
                        axis: 'x'
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: graphType === 'ttk' ? (context) => `${context.dataset.label}: ${Math.round(context.parsed.y)} ms` : undefined,
                            },
                            usePointStyle: true,
                        },
                        legend: {
                            position: 'bottom',
                            labels: {
                                pointStyle: 'line',
                                usePointStyle: true,
                                color: '#ccc'
                            }
                        },
                        title: {display: false},
                        zoom: {
                            zoom: {
                                wheel: {
                                    enabled: true,
                                },
                                pinch: {
                                    enabled: true
                                },
                                mode: 'xy',
                            },
                            pan: {
                                enabled: true,
                            },
                            limits: {
                                x: {min: 0, max: 150},
                                y: {min: 0, max: 5000}
                            }
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: t('range'),
                                color: '#ccc'
                            },
                            grid: {
                                color: '#333'
                            },
                            ticks: {
                                color: '#ccc'
                            }
                        },
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: {
                                    ttk: `${t('ttk')} (ms)`,
                                    stk: t('stk'),
                                    damage: t('Damage')
                                }[graphType],
                                color: '#ccc'
                            },
                            grid: {
                                color: '#333'
                            },
                            ticks: {
                                color: '#ccc'
                            }
                        }
                    }
                }
            })
            return () => {
                chart.destroy()
            }
        },
        [graphType, data, i18n.resolvedLanguage]
    )

    useEffect(() => {
        const allDatasets = [
            weapon ? calculateDatapoints(weapon, 'red') : null,
            ...comparisonLoadouts.map((cl, i) => calculateDatapoints(cl.calculatedWeapon, lineColors[i], cl.name))
        ].filter(ds => ds != null)
        if (_.isEqual(data.datasets, allDatasets)) {
            return
        }
        setData({...data, datasets: allDatasets})
    }, [weapon, comparisonLoadouts, graphType])

    return <div style={{marginTop: '10px', height: isMobile ? 300 : 420, width: isMobile ? 325 : 700}}>
        <canvas ref={graphRef} id="weapon-stats-chart" style={{width: 750}}></canvas>
    </div>;
}


export default NewGraph
