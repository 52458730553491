export const allWeapons = [
    {
        name: "ACR",
        type: "Assault Rifle",
        rateOfFire: 625,
        adsTime: 320,
        sprintToFire: 320,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 30,
        reloadTime: 2300,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 6,
        hipfire: 5,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 33, damage: 21}, {range: 45, damage: 19}, {range: 300, damage: 17}],
        multipliers: {head: 1.4},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3", "o4",
            "z1", "z2", "z3", "z4",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s5", "s6"
        ]
    },
    {
        name: "M4A1",
        type: "Assault Rifle",
        rateOfFire: 725,
        adsTime: 267,
        sprintToFire: 267,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 30,
        reloadTime: 2200,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 6,
        hipfire: 5,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 38, damage: 17}, {range: 50, damage: 16}, {range: 300, damage: 14}],
        multipliers: {head: 1.4},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3", "o4",
            "z1", "z2", "z3", "z4",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s5"
        ]
    },
    {
        name: "AK-47",
        type: "Assault Rifle",
        rateOfFire: 600,
        adsTime: 300,
        sprintToFire: 300,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 30,
        reloadTime: 2100,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 18,
        hipfire: 5,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 29, damage: 22}, {range: 43, damage: 20}, {range: 300, damage: 18}],
        multipliers: {head: 1.5},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3", "o4",
            "z1", "z2", "z3", "z4",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s5", "s6"
        ]
    },
    {
        name: "M16A4",
        type: "Assault Rifle",
        rateOfFire: 275,
        burstRateOfFire: { burst: 63, pause: 217 },
        adsTime: 300,
        sprintToFire: 300,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 30,
        reloadTime: 2200,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 6,
        hipfire: 5,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 34, damage: 24}, {range: 45, damage: 21}, {range: 300, damage: 17}],
        multipliers: {head: 1.55},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3", "o4",
            "z0", "z2", "z3", "z20",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s5"
        ]
    },
    {
        name: "MDR",
        type: "Assault Rifle",
        rateOfFire: 765,
        adsTime: 300,
        sprintToFire: 250,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 30,
        reloadTime: 2100,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 6,
        hipfire: 4,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 20, damage: 17}, {range: 31, damage: 16}, {range: 300, damage: 14}],
        multipliers: {head: 1.4},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3", "o4",
            "z1", "z2", "z3", "z4",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4",
        ]
    },
    {
        name: "LVOA-C",
        type: "Assault Rifle",
        rateOfFire: 800,
        adsTime: 250,
        sprintToFire: 250,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 30,
        reloadTime: 2300,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 6,
        hipfire: 5,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 32, damage: 17}, {range: 44, damage: 16}, {range: 300, damage: 14}],
        multipliers: {head: 1.4},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3", "o4",
            "z1", "z2", "z3", "z4",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s5"
        ]
    },
    {
        name: "MP5A2",
        type: "SMG",
        rateOfFire: 800,
        adsTime: 175,
        sprintToFire: 175,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 30,
        reloadTime: 2400,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 14,
        hipfire: 4,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 16, damage: 18}, {range: 25, damage: 13}, {range: 100, damage: 10}],
        multipliers: {head: 1.4},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3",
            "z1", "z2", "z3", "z4",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s5", "s6"
        ]
    },
    {
        name: "MP7",
        type: "SMG",
        rateOfFire: 875,
        adsTime: 185,
        sprintToFire: 185,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 35,
        reloadTime: 1900,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 14,
        hipfire: 4,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 17, damage: 19}, {range: 22, damage: 11}, {range: 100, damage: 8}],
        multipliers: {head: 1.2},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3",
            "z1", "z2", "z3", "z4",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s5", "s9"
        ]
    },
    {
        name: "P90",
        type: "SMG",
        rateOfFire: 825,
        adsTime: 170,
        sprintToFire: 170,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 50,
        reloadTime: 2800,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 14,
        hipfire: 4,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 18, damage: 18}, {range: 24, damage: 13}, {range: 100, damage: 10}],
        multipliers: {head: 1.4},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f2", "f4", "f6", "f8",
            "o1", "o2", "o3",
            "z18", "z2", "z15",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3"
        ]
    },
    {
        name: "Vector .45",
        type: "SMG",
        rateOfFire: 1000,
        adsTime: 180,
        sprintToFire: 180,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 25,
        reloadTime: 2100,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 14,
        hipfire: 4,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 13, damage: 19}, {range: 19, damage: 11}, {range: 100, damage: 8}],
        multipliers: {head: 1.2},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3",
            "z1", "z2", "z3", "z4",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s5", "s6"
        ]
    },
    {
        name: "M249",
        type: "LMG",
        rateOfFire: 600,
        adsTime: 425,
        sprintToFire: 425,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 100,
        reloadTime: 9000,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 34,
        hipfire: 6,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 31, damage: 23}, {range: 42, damage: 20}, {range: 300, damage: 18}],
        multipliers: {head: 1.4},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3", "o4", "o6",
            "z26", "z27", "z9", "z28",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s6"
        ]
    },
    {
        name: "RPK-74",
        type: "LMG",
        rateOfFire: 525,
        adsTime: 475,
        sprintToFire: 475,
        moveSpeed: 0.9,
        adsMoveSpeed: 0.9,
        magSize: 45,
        reloadTime: 3000,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 11,
        hipfire: 6,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 36, damage: 23}, {range: 48, damage: 21}, {range: 300, damage: 20}],
        multipliers: {head: 1.4},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3", "o4", "o6",
            "z1", "z23", "z10", "z16",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s6"
        ]
    },
    {
        name: "M60",
        type: "LMG",
        rateOfFire: 500,
        adsTime: 525,
        sprintToFire: 525,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 100,
        reloadTime: 9400,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 11,
        hipfire: 6,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 38, damage: 25}, {range: 50, damage: 23}, {range: 300, damage: 20}],
        multipliers: {head: 1.4},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3", "o4", "o6",
            "z24", "z23", "z9", "z25",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s9"
        ]
    },
    {
        name: "M870",
        type: "Shotgun",
        rateOfFire: 80,
        adsTime: 200,
        sprintToFire: 250,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 5,
        reloadTime: 900,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 19,
        hipfire: 4,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 10, damage: 14}, {range: 15, damage: 8}, {range: 35, damage: 6}],
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7", "b8",
            "f1", "f2", "f3", "f4", "f5", "f10", "f7", "f8", "f9",
            "o1", "o2", "o3",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4",
        ]
    },
    {
        name: "AA-12",
        type: "Shotgun",
        rateOfFire: 300,
        adsTime: 200,
        sprintToFire: 200,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 8,
        reloadTime: 2500,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 19,
        hipfire: 6,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 7, damage: 8}, {range: 10, damage: 5}, {range: 30, damage: 4}],
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7", "b8",
            "f1", "f2", "f3", "f4", "f5", "f10", "f7", "f8", "f9",
            "o1", "o2", "o3",
            "z18", "z2", "z22", "z4",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4"
        ]
    },
    {
        name: "Double-Barreled",
        type: "Shotgun",
        rateOfFire: 200,
        adsTime: 200,
        sprintToFire: 250,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 2,
        reloadTime: 1000,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 19,
        hipfire: 4,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 12, damage: 15}, {range: 16, damage: 10}, {range: 40, damage: 7}],
        attachments: [
            "b8",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s10", "s2", "s3", "s4",
        ]
    },
    {
        name: "MK 20 SSR",
        type: "Marksman Rifle",
        rateOfFire: 200,
        adsTime: 350,
        sprintToFire: 350,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 10,
        reloadTime: 2000,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 24,
        hipfire: 6,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 40, damage: 49}, {range: 60, damage: 43}, {range: 300, damage: 41}],
        multipliers: {
            head: 1.8,
            chest: 1.4
        },
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3", "o4", "o5", "o6", "o7",
            "z17", "z2", "z5", "z14",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s6",
        ]
    },
    {
        name: "SVD",
        type: "Marksman Rifle",
        rateOfFire: 185,
        adsTime: 350,
        sprintToFire: 350,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 10,
        reloadTime: 3000,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 24,
        hipfire: 6,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 45, damage: 49}, {range: 70, damage: 47}, {range: 300, damage: 45}],
        multipliers: {
            head: 2,
            chest: 1.4
        },
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9",
            "o1", "o2", "o3", "o4", "o5", "o6", "o7",
            "z17", "z2", "z5", "z14",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4", "s6",
        ]
    },
    {
        name: "M44",
        type: "Sniper Rifle",
        rateOfFire: 48,
        adsTime: 400,
        sprintToFire: 350,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 5,
        reloadTime: 1000,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 60,
        hipfire: 11,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 30, damage: 70}, {range: 50, damage: 70}, {range: 300, damage: 70}],
        multipliers: {
            head: 1.8,
            chest: 1.6,
            leg: 0.8
        },
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "o4", "o5", "o6", "o7",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4",
        ]
    },
    {
        name: "TAC-50",
        type: "Sniper Rifle",
        rateOfFire: 38,
        adsTime: 600,
        sprintToFire: 625,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 5,
        reloadTime: 3100,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 80,
        hipfire: 11,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 27, damage: 90}, {range: 45, damage: 90}, {range: 300, damage: 90}],
        multipliers: {
            head: 1.8,
            chest: 1.6,
            leg: 0.8
        },
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "o5", "o6", "o7",
            "z18", "z12", "z11", "z15",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4",
        ]
    },
    {
        name: "L115",
        type: "Sniper Rifle",
        rateOfFire: 42,
        adsTime: 550,
        sprintToFire: 575,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 5,
        reloadTime: 3000,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 80,
        hipfire: 11,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 30, damage: 80}, {range: 50, damage: 80}, {range: 300, damage: 80}],
        multipliers: {
            head: 1.8,
            chest: 1.6,
            leg: 0.8
        },
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "b1", "b2", "b3", "b4", "b5", "b6", "b7",
            "o5", "o6", "o7",
            "z18", "z12", "z11", "z15",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s1", "s2", "s3", "s4",
        ]
    },
    {
        name: "M1911",
        type: "Pistol",
        rateOfFire: 335,
        adsTime: 150,
        sprintToFire: 150,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 7,
        reloadTime: 1600,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 4,
        hipfire: 5,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 10, damage: 30}, {range: 20, damage: 24}, {range: 50, damage: 18}],
        multipliers: {head: 1.6},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "o1", "o8",
            "z18", "z2", "z6", "z15",
            "g1", "g2", "g3", "g4", "g5", "g6",

        ]
    },
    {
        name: "686 Magnum",
        type: "Pistol",
        rateOfFire: 150,
        adsTime: 150,
        sprintToFire: 150,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 6,
        reloadTime: 3000,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 14,
        hipfire: 5,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 10, damage: 48}, {range: 30, damage: 44}, {range: 60, damage: 34}],
        multipliers: {head: 2},
        attachments: [
            "m2", "m3", "m4", "m5",
            "o1", "o8",
            "g1", "g2", "g3", "g4", "g5", "g6",
        ]
    },
    {
        name: "93R",
        type: "Pistol",
        rateOfFire: 350,
        adsTime: 150,
        sprintToFire: 150,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 15,
        reloadTime: 1700,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 4,
        hipfire: 6,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 10, damage: 17}, {range: 20, damage: 15}, {range: 50, damage: 13}],
        multipliers: {head: 1.8},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "o1", "o8",
            "z18", "z2", "z22", "z15",
            "g1", "g2", "g3", "g4", "g5", "g6",
            "s3", "s8"
        ]
    },
    {
        name: "M9",
        type: "Pistol",
        rateOfFire: 400,
        adsTime: 150,
        sprintToFire: 150,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 15,
        reloadTime: 1500,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 4,
        hipfire: 5,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 10, damage: 24}, {range: 20, damage: 20}, {range: 50, damage: 15}],
        multipliers: {head: 1.6},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "o1", "o8",
            "z18", "z2", "z3", "z15",
            "g1", "g2", "g3", "g4", "g5", "g6",
        ]
    },
    {
        name: "D50",
        type: "Pistol",
        rateOfFire: 200,
        adsTime: 150,
        sprintToFire: 150,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 7,
        reloadTime: 1600,
        recoil: {vertical: 50, horizontal: 50, recovery: 50},
        aimStability: 4,
        hipfire: 5,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damageRanges: [{range: 10, damage: 45}, {range: 30, damage: 33}, {range: 50, damage: 24}],
        multipliers: {head: 2},
        attachments: [
            "m1", "m2", "m3", "m4", "m5", "m6",
            "o1", "o8",
            "z18", "z13", "z8", "z15",
            "g1", "g2", "g3", "g4", "g5", "g6",

        ]
    }
]
