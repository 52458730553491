import {Stack, Typography} from "@mui/material";

const MIN_NUMBER_OF_TEAMS = 1;

const table = [
    {
        column: 'name',
        heading: 'Player',
        width: 45,
    },
    {
        column: 'kills',
        heading: 'Kills',
        width: 15,
    },
    {
        column: 'deaths',
        heading: 'Deaths',
        width: 15,
    },
    {
        column: 'assists',
        heading: 'Assists',
        width: 15,
    },
]

const Player = ({header, player}) => {
    if (header) player = {name: 'Player', kills: 'Kills', deaths: 'Deaths', assists: 'Assists'}
    return <Stack direction='row' color='white' m='5px 0' borderRadius='15px' p={header ? '0 15px' : '10px 15px'}
                  backgroundColor={header ? null : 'rgba(0,0,0,0.25)'}>
        {table.map(col =>
            <Typography variant={header ? 'midHead' : 'mid'} minWidth={col.width + '%'}>
                {header ? col.heading : player[col.column]}
            </Typography>)}
    </Stack>
}

const Team = ({team}) => {
    const players = team.players ?? [];
    return <Stack width='calc(100% - 40px)' m='10px 0' p='15px 20px'
                  backgroundColor='background.paper' borderRadius='10px'
                  boxShadow='0 0 5px rgba(0,0,0,0.8)'>
        <Typography variant='midHead' fontStyle='normal' mb='10px'>{team.name}</Typography>
        <Player header/>
        {players.filter(p => p != null).map(p => <Player player={p}/>)}
    </Stack>

}
const Teams = ({teams}) => {
    if (teams?.length < MIN_NUMBER_OF_TEAMS) throw Error("Not enough teams.")
    return <Stack width='100%'>
        {teams.filter(t => t != null).map(t => <Team team={t}/>)}
    </Stack>
}

export default Teams