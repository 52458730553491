import {useParams} from "react-router-dom";
import {Stack} from "@mui/material";
import MainBg from "../assets/main-bg.png";
import MatchData from "./MatchData";

const backgroundImage = {
    height: '100%', width: '100%', position: 'absolute', backgroundImage: `url(${MainBg})`,
    backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%',
    alignItems: 'center', overflow: 'auto', '&::-webkit-scrollbar': {display: 'none'}
}

const backgroundGradient = {
    width: '100vw',
    height: '100vh',
    backgroundImage: 'linear-gradient(170deg, #3c3c40 0%, #1F1F21 95%)'
};

const Content = () => {
    return <Stack width='70%' minWidth='1200px'>
        <MatchData/>
    </Stack>
}

const OverwolfOverlay = () => {
    const {userId} = useParams()
    return <Stack sx={backgroundGradient}>
        <Stack sx={backgroundImage}>
            <Content/>
        </Stack>
    </Stack>
}

export default OverwolfOverlay