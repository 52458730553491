import React from 'react';
import './index.css';
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import WeaponScreen from "./builder/WeaponScreen";
import LoadoutCard from "./builder/share/LoadoutCard";
import AboutPage from "./pages/AboutPage";
import ErrorPage from "./pages/ErrorPage";
import MetaPage, {MetaCategories} from "./pages/MetaPage";
import AuthHandler from "./auth/AuthHandler";
import ProfilePage from "./pages/profile/ProfilePage";
import ProfilePreview from "./pages/profile/ProfilePreview";
import RedeemCodePage from "./pages/RedeemCodePage";
import BookmarksPage from "./pages/BookmarksPage";
import HomeScreen from "./builder/HomeScreen";
import {Stack, Typography} from "@mui/material";
import XDLoadoutLogo from "./components/XDLoadoutLogo";
import FeaturedProfilesPage from "./pages/FeaturedProfilesPage";
import UpdatesPage from "./pages/UpdatesPage";
import LeaderboardsPage from "./pages/LeaderboardsPage";
import StatTrackPage from "./pages/StatTrackPage";
import OverwolfOverlay from "./overwolf/OverwolfOverlay";

const Create = () => {
    return <Stack sx={{
        position: 'absolute', left: 0, top: 0, zIndex: -100,
        overflow: 'clip',
        backgroundImage: 'linear-gradient(165deg, #3c3c40 20%, #1F1F21 85%)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'background.default',
        alignItems: 'center'
    }} width={1200} height={800}>
        <XDLoadoutLogo height={100}/>
    </Stack>
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "",
                element: <HomeScreen/>
            },
            {
                path: "builder",
                element: <WeaponScreen/>
            },
            {
                path: "about",
                element: <AboutPage/>
            },
            {
                path: "updates",
                element: <UpdatesPage/>
            },
            {
                path: "redeem-code",
                element: <RedeemCodePage/>
            },
            {
                path: "meta",
                element: <MetaPage/>
            },
            {
                path: "meta/competitive",
                element: <MetaPage category={MetaCategories.Ranked}/>
            },
            {
                path: "meta/ranked",
                element: <MetaPage category={MetaCategories.Ranked}/>
            },
            {
                path: "meta/competitive/:metaWeapon",
                element: <MetaPage category={MetaCategories.Ranked}/>
            },
            {
                path: "meta/ranked/:metaWeapon",
                element: <MetaPage category={MetaCategories.Ranked}/>
            },
            {
                path: "meta/:metaWeapon",
                element: <MetaPage/>
            },
            {
                path: "profiles/featured",
                element: <FeaturedProfilesPage/>
            },
            {
                path: "bookmarks",
                element: <BookmarksPage/>
            },
            {
                path: "stat-track",
                element: <StatTrackPage/>
            },
            {
                path: "leaderboards/:category",
                element: <LeaderboardsPage/>
            },
            {
                path: "loadouts/:loadoutId",
                element: <WeaponScreen/>
            },
            {
                path: "profile/:userId",
                element: <ProfilePage/>
            },
            {
                path: "oauth/discord",
                element: <AuthHandler/>
            },
            {
                path: "*",
                element: <ErrorPage/>
            }
        ]
    },
    {
        path: "/loadouts/:loadoutId/card",
        element: <LoadoutCard/>
    },
    // {
    //     path: "create",
    //     element: <Create/>
    // },
    {
        path: "profile/:userId/preview",
        element: <ProfilePreview/>
    },
    {
        path: "overlay/:userId",
        element: <OverwolfOverlay/>,
        errorElement: <Typography>Error</Typography>
    },
])

const Routes = () => <RouterProvider router={router}/>

export default Routes