import {Avatar, Skeleton, Stack, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {TitleText} from "../components/Text";
import {getUserProfile} from "../api/user/UserProfile";
import {useIsMobileHook} from "../utils";
import {MainGradient} from "../App";
import {WeaponImage} from "../components/WeaponImage";
import {useNavigate} from "react-router-dom";
import MobileAd, {MobileSlots} from "../components/ads/MobileAd";
import {Namespaces} from "../config/localisation/Localisation";
import {useTranslation} from "react-i18next";
import {PlayerProgression} from "./profile/stats/UserStats";


const FeaturedProfilesPage = ({}) => {
    const {t} = useTranslation(Namespaces.profiles)
    return <Stack width='100%' alignItems='center'>
        <Stack sx={{mt: {xs: 0, md: '10px'}, width: {xs: '97%', md: '80%'}}}>
            <TitleText>
                {t('title')}
            </TitleText>
            <Stack alignItems='center'>
                <FeaturedProfiles/>
            </Stack>
        </Stack>
    </Stack>
}

const FavouriteWeapons = ({data}) => {
    const favourites = useMemo(() => {
        const fav = data.loadouts.filter(l => l.favourite)
        return fav.length > 0 ? fav : data.loadouts.slice(0, 5)
    })


    return <Stack direction='row' alignItems='baseline'>
        {favourites.map(l =>
            <Stack alignItems='center' m='0 8px'>
                <WeaponImage sillhouette weapon={l.weapon.name} style={{
                    height: 'auto',
                    maxHeight: '25px',
                    maxWidth: '80px'
                }}/>
                <Typography variant='smallHead' mt='3px'>{l.weapon.name}</Typography>
            </Stack>)}
    </Stack>;
}

const FeaturedProfile = ({data}) => {
    const isMobile = useIsMobileHook()
    const navigate = useNavigate()
    return <Stack direction='row'
                  onClick={() => navigate(`/profile/${data.profileUrl}`)}
                  sx={{
                      p: '15px 20px',
                      m: '5px 0',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      color: 'white',
                      backgroundImage: MainGradient,
                      borderRadius: '8px',
                      boxShadow: '0 0 5px #141414',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease',
                      '&:hover': {boxShadow: '0 0 5px #aaa'}
                  }}>
        <Stack direction='row' alignItems='center'>
            <Avatar src={data.icon} sx={{
                height: isMobile ? 36 : 48,
                width: isMobile ? 36 : 48,
                boxShadow: '0 0 10px 0 white',
                mr: isMobile ? '5px' : '15px'
            }}/>
            <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'start' : 'center'} ml='10px'>
                <Typography variant='midHead' fontWeight='normal' mr='15px'>{data.displayName}</Typography>
                <PlayerProgression stats={data.stats?.progression} small={isMobile}/>
            </Stack>
        </Stack>
        {isMobile ? null : <FavouriteWeapons data={data}/>}
    </Stack>
}

const FeaturedSection = ({section}) => {
    const {t} = useTranslation(Namespaces.profiles)
    const isMobile = useIsMobileHook()
    const [expanded, setExpanded] = useState(1)

    const pageSize = isMobile ? 3 : 5

    const profilesToShow = section.profiles.slice(0, Math.min(pageSize * expanded, section.profiles.length))
    return <Stack alignItems='center' width='100%' p='15px 25px 20px' m='5px' backgroundColor='rgba(0,0,0,0.3)'
                  borderRadius='5px'>
        <Typography variant='midHead' p='0 5px' alignSelf='start'>{t(section.title)}</Typography>
        <Stack mt='5px' width='calc(100% - 30px)'>
            {profilesToShow.map(p => <FeaturedProfile data={p}/>)}
        </Stack>
        <Stack mt='10px' p='5px 15px' width='fit-content' border='2px solid #999' borderRadius='5px'
               onClick={() => setExpanded(expanded + 1)}
               sx={{
                   display: expanded * pageSize >= section.profiles.length ? 'none' : null,
                   cursor: 'pointer',
                   '&:hover': {backgroundColor: 'rgba(60,60,60,0.5)'}
               }}>
            <Typography fontSize='0.9rem' color='white'>{t('seeMore')}</Typography>
        </Stack>
    </Stack>
}

const FeaturedProfiles = () => {
    const {t} = useTranslation(Namespaces.profiles)
    const [featuredData, setFeaturedData] = useState('loading')

    useEffect(() => {
        getUserProfile('featured')
            .then(fp => setFeaturedData(fp.map(section => {
                return {
                    ...section,
                    profiles: section.profiles
                        .filter(p => p.loadouts.length > 0)
                        .map(value => ({value, sort: Math.random()}))
                        .sort((a, b) => a.sort - b.sort)
                        .map(({value}) => value)
                }
            })))
    }, [])

    return featuredData === 'loading' ? <BookmarkSkeleton/> :
        featuredData === 'failed' ?
            <Stack alignItems='center'>
                <Typography color='white'>{t('failedGet')}</Typography>
            </Stack>
            :
            <Stack width='100%' alignItems='center'>
                {featuredData.flatMap((section, i) => [
                    <FeaturedSection section={section}/>,
                    i === 0 ? <MobileAd slot={MobileSlots.HORIZONTAL_1} nitroSlot='featured-1'/> : null
                ])}
            </Stack>
}

const BookmarkSkeleton = () => <Stack width='100%'>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
</Stack>

export default FeaturedProfilesPage